<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="状态" label-width="100">
                <el-select v-model="applicationStatus" clearable placeholder="状态">
                    <el-option label="申请中" value="1"></el-option>
                    <el-option label="已打款" value="2"></el-option>
                    <el-option label="拒绝" value="3"></el-option>
                    <el-option label="打款中" value="4"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="打款时间" label-width="100">
                <el-date-picker v-model="Time" type="daterange" range-separator="至" clearable start-placeholder="开始日期"
                    end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="current = 1, getCustomerservice()">搜索</el-button>
                <el-button type="primary" @click="exportSurfList()">导出</el-button>
                <el-button @click="sqdk()" type="success" v-if="hasAuth('sys:commissionapplication:sqdk')">申请打款</el-button>
            </el-form-item>
            <el-form-item id="selectForm" label="可代付金额" label-width="200px" style="float: right;">
                <span style="color: #e74c3c;font-size: 28px">{{ paymentBalance }}元</span>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column prop="id" align="center" label="ID" width="100">
            </el-table-column>

            <el-table-column prop="nickName" align="center" label="提现人昵称" width="220">
            </el-table-column>
            <el-table-column prop="phone" align="center" label="提现人电话" width="140">
            </el-table-column>
            <el-table-column prop="deaMoney" align="center" label="提现金额" width="120">
            </el-table-column>
            <el-table-column prop="withdrawalType" align="center" label="提现类型" width="100">
                <template slot-scope="{row}">
                    <span v-if="row.withdrawalType == 1">银行卡</span>
                    <span v-if="row.withdrawalType == 2">微信余额</span>
                </template>
            </el-table-column>
            <el-table-column prop="applicationStatus" align="center" label="申请状态" width="100">
                <template slot-scope="{row}">
                    <span v-if="row.applicationStatus == 1">申请中</span>
                    <span v-if="row.applicationStatus == 2" style="color:green">已打款</span>
                    <span v-if="row.applicationStatus == 3" style="color:red">拒绝</span>
                    <span v-if="row.applicationStatus == 4" style="color: #3a8ee6">打款中</span>
                </template>
            </el-table-column>
            <el-table-column prop="trxTime" align="center" label="打款时间" width="200">
            </el-table-column>
            <el-table-column prop="bankName" align="center" label="银行名称" width="200">
            </el-table-column>
            <el-table-column prop="bankCode" align="center" label="开户行" width="300">
            </el-table-column>
            <el-table-column prop="customerName" align="center" label="开户人姓名" width="200">
            </el-table-column>
            <el-table-column prop="cardNumber" align="center" label="银行卡卡号" width="160">
            </el-table-column>
            <el-table-column prop="icon" align="center" width="200" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="saveHandle(scope.row)"
                        v-if="hasAuth('sys:commissionapplication:update') && scope.row.applicationStatus == 1">审核</el-button>
                    <el-button type="primary" plain @click="qrypaymentstatus(scope.row.id)"
                        v-if="scope.row.applicationStatus != 1">打款详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="current"
            :page-size="size" :total="total">
        </el-pagination>
        <el-dialog title="打款审核" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-form :model="editForm" ref="editForm">
                <el-form-item label="审核状态：" label-width="150px">
                    <el-radio-group v-model="editForm.applicationStatus">
                        <el-radio :label="1">已打款</el-radio>
                        <el-radio :label="2">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注：" label-width="150px" v-if="editForm.applicationStatus == 2">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="editForm.rejectionReason" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="invoiceTrue">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="打款详情" :visible.sync="paymentDialogVisible" width="45%" :before-close="handlePaymentClose">
            <el-row :gutter="20" style="padding-bottom:10px ">
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            打款商户号：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.mchntNo }}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            打款卡号：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.cardNo }}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row :gutter="20" style="padding-bottom:10px ">
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            打款流水号：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.ssn }}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            打款时间：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.trxTime }}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row :gutter="20" style="padding-bottom:10px ">
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            打款金额：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.transAmt }}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            打款状态：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.desc }}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row :gutter="20" style="padding-bottom:10px ">
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            系统订单号：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.sysOrderId }}
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            系统编号：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.sysId }}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-row :gutter="20" style="padding-bottom:10px ">
                <el-col :span="12">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            备注：
                        </el-col>
                        <el-col :span="18">
                            {{ qrypaymentstatusData.remark }}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-dialog>
        <Sqdk ref="sqdk" v-if="sqdkvisible" @refreshDataList="shuaxin"></Sqdk>
    </div>
</template>

<script>
import Sqdk from "./Sqdk";
import ty from "@/utils/ty";
import axios from 'axios';
export default {
    components: {
        Sqdk
    },
    data() {
        return {
            Time: [],
            editForm: {},
            dialogVisible: false,
            tableData: [],
            saveInsuranceTypevisible: false,
            applicationStatus: null,
            size: 10,
            current: 1,
            total: 0,
            paymentBalance: 0,
            qrypaymentstatusData: {},
            sqdkvisible: false,
            paymentDialogVisible: false,
        }
    },
    created() {
        this.getCustomerservice();
        this.qrypaymentbalance()
    },
    methods: {
        handleExport() {
            axios({
                url: ty.apiurl + "/admin/commissionapplication/exportSurfList",
                method: 'post',
                data: {
                    pageNo: this.current,
                    pageSize: this.size,
                    applicationStatus: this.applicationStatus,
                    startTime: this.Time && this.Time.length > 0 ? this.Time[0] : null,
                    endTime: this.Time && this.Time.length > 0 ? this.Time[1] : null,
                },
                responseType: 'blob',
                headers: {
                    'X-Token': localStorage.getItem('token') || '',
                },
            })
                .then(res => {
                   
                    let blob = new Blob([res.data], { type: res.data.type });
                    let url = window.URL.createObjectURL(blob);
                    console.log(url);
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    link.setAttribute("download","提现管理.xlsx");//文件名后缀记得添加，我写的zip
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);//下载完成移除元素
                    window.URL.revokeObjectURL(url);//释放掉blob对象
                })
        },
        async exportSurfList() {
            this.handleExport()

        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.getCustomerservice()
        },
        shuaxin() {
            this.getCustomerservice();
            this.qrypaymentbalance()
        },
        handleCurrentChange(val) {
            this.current = val
            this.getCustomerservice()
        },
        getCustomerservice() {
            this.$axios.post("/admin/commissionapplication/getApplicationList",
                {
                    pageNo: this.current,
                    pageSize: this.size,
                    applicationStatus: this.applicationStatus,
                    startTime: this.Time && this.Time.length > 0 ? this.Time[0] : null,
                    endTime: this.Time && this.Time.length > 0 ? this.Time[1] : null,
                }).then(res => {
                    this.tableData = res.data.records
                    this.total = res.data.total
                })
        },
        qrypaymentbalance() {
            this.$axios.post("/admin/commissionapplication/qrypaymentbalance").then(res => {
                this.paymentBalance = res.data.paymentBalance
            })
        },
        handleClose() {
            this.editForm = {}
            this.dialogVisible = false
        },
        handlePaymentClose() {
            this.qrypaymentstatusData = {}
            this.paymentDialogVisible = false
        },
        saveHandle(row) {
            this.editForm = JSON.parse(JSON.stringify(row))
            this.editForm.rejectionReason = null
            this.dialogVisible = true
        },
        qrypaymentstatus(id) {
            this.$axios.post("/admin/commissionapplication/qrypaymentstatus", { id: id }).then(res => {
                this.qrypaymentstatusData = res.data
            })
            this.paymentDialogVisible = true
        },
        invoiceTrue() {
            var that = this
            if (that.editForm.applicationStatus == 2 && !this.editForm.rejectionReason) {
                that.$message('请输入拒绝原因');
                return false;
            }
            if (that.editForm.applicationStatus == 1) {
                that.$axios.post("/admin/commissionapplication/applicationPaid", { id: that.editForm.id }).then(res => {
                    that.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose: () => {
                            that.dialogVisible = false
                            that.current = 1
                            that.getCustomerservice()
                        }
                    });
                })
            }
            if (that.editForm.applicationStatus == 2) {
                that.$axios.post("/admin/commissionapplication/applicationReject", { id: that.editForm.id, rejectionReason: this.editForm.rejectionReason }).then(res => {
                    that.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose: () => {
                            that.dialogVisible = false
                            that.current = 1
                            that.getCustomerservice()
                        }
                    });
                })
            }
        },
        sqdk() {
            this.sqdkvisible = true;
            this.$nextTick(() => {
                this.$refs.sqdk.init();
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}

#selectForm>>>.el-form-item__label {
    font-size: 28px;
    color: #e74c3c;
}
</style>

